/* navbar */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:10px 30px 10px 20px;
  background-color: rgba(255, 255, 255);
  box-shadow: 10px 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 98%;
  z-index: 99999; 
  position: static;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1.25rem;
}

nav ul li {
  cursor: pointer;
}
.mann{
  color: #4caf50;
}
.logo{
  width:105px;
  height: 70px;
}
.mylogo1{
  width:150px;
  height: 60px;
}

.burg{
position: absolute;
width: 40px;
height: 40px;
right: 20px;
top: 20px;
display: none;
}


@media only screen and (max-width:476px){
  .navm{
    width: 375px;
  }
  
  
  
  .burg{
    display: block;
    width: 20px;
height: 20px;
  }

  .hover{
    display: none;
    position: absolute;
    flex-direction: column;
    background-color: #fff;
    top:55px;
    width: 100%;
    left: 0;
    padding: 0;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
 
}
.active{
display:flex;
z-index:9999;
}
  .burg{
    cursor: pointer;
  }
.navr{
  padding:10px 0px 0px 20px;
  width: auto;
}
.mylogo1 {
  height: 50px;
  width: 150px;
 margin-top: -15px;
}
}

  