
/* src/ActivityPage.css */
.activity-container {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 50%;
    margin: 50px auto;
  }
  
  .activity-list {
    list-style-type: none;
    padding: 0;
  }
  
  .activity-list li {
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .activity-location {
    font-weight: bold;
  }
  
  .activity-visitors {
    color: #666;
  }
  .mc{
    margin-top: 500px;
  }
  