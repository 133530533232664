body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parent{
  display: flex;
  flex-direction: row;
  width: 300%;
  animation-name: slide;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  z-index: 88;
  height: 100vh;
    
}
@keyframes slide{
  0%{
  transform: translateX(0);

  }
  33.33%{
    transform: translateX(-33.33%);
}
66.66%{
  transform: translateX(-66.66%);
}
100%{
  transform: translateX(0);

}
}
.cnttt{
  position: absolute  ;
  display: flex;
  flex-direction: column  ;
  align-items: center;
  justify-content: center;
  z-index: 99;
  text-align: center;
width: 100%;
height: 100vh;
}

.transparent-image {
  width: 33.33%;
  text-align: center;
  margin: 0px;
  padding: 170px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 object-fit: cover; 
  background-size: cover;
  
}



ul{
  list-style: none;
}
.transparent-image2 {
  width: 33.33%;

  text-align: center;
  margin: 0px;
  padding: 170px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  object-fit: cover; 
  background-size: cover;
  
}
.transparent-image3 {
  width: 33.33%;

  text-align: center;
  margin: 0px;
  padding: 170px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 
  object-fit: cover; 
  background-size: cover;
  
}

.transparent-image h1 {
  margin-bottom: 10px;
}

.transparent-image p {
  font-size: 16px;
  margin-bottom: 20px;
}

.transparent-image button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  border-radius: 20px;
}

.transparent-image button:hover {
  background-color: #45a049;
}



.se{
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  margin-bottom: -30px;
}

.hover>li>a:hover{

  text-decoration: none;

}

.share-experience {
 width: 50%;
 margin-bottom: 70px;
}
.homeimg{
  width: 100%;
}

.irc{
   width: 50%;
   text-align: left;
   padding: 50px;
   margin-bottom: 70px;
}
.visit{
  
  color: #003b4a;
  font-size: 30px
}
.vs1{
  margin-bottom: -70px;
  color: #4caf50;

}

.travel{
  text-align: justify;
}

.btn1{
  padding: 15px;
  border-radius: 20px;
  border: none; 
  background-color: #45a049;
  color: #fff;
  width: 10.313rem;
  text-decoration: none;
}



.btn2{
  padding: 15px;
  border-radius: 20px;
  border: none; 
  background-color: #45a049;
  color: #fff;
  width: 200px;
  text-decoration: none;
 margin: 0%;
  
}


.swipe{
  align-self: flex-start;
  margin-top: 10%;
  height: 100px;
  width: 5px;
  background-color: green;
  cursor: pointer;
}
.icons{
  transition: 0.7s ease;
}
.swipe:hover .icons{
  transform: translateX(-50px);
  transition: 0.7s ease;
}

.iconlist{
  list-style: none;
  padding: 10px;
}
.swipeicon{
  margin: 10px 0 10px 0;
}
.share-experience h2 {
  margin-bottom: 10px;
}

.share-experience button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.share-experience button:hover {
  background-color: #45a049;
}




.transparent {
  text-align: center;
  margin: 0px;
  padding: 150px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-image: url('./asset/imge.jpg');
  object-fit: cover; 
  height: 200px;
  background-size: cover;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 }

.left{
  left: auto;
  width:1000px;
  height: 50px;
  color: white;
  justify-content: center;
  align-items: center;
  
}

.transparent h1 {
  margin-bottom: 10px;
}

.transparent p {
  font-size: 16px;
  margin-bottom: 20px;
}

.transparent button {
  background-color: #003b4a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  border-radius: 20px;
  float: right;
  height: 60px;
 margin-top: 150px;
}

.transparent button:hover {
  background-color: #45a049;
}
/* //mission section */
.container1 {
  display: flex;
  justify-content: space-around;
}

.left1, .right2 {
  width: 45%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.t2>b{
  text-align: center;
}

.r2 {
  font-size: 34px;
  margin-bottom: 10px;
  color: #003b4a;
}

.t2 {
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
 
}

.m{
  align-self: center;
  text-align: center;
  margin-top: 5rem;
  color: black;
}

.m1{
    text-align: center;

  margin-top: 100px;
  color: #003b4a;
}
.pp1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 97%;
  padding: 0 20px;
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center;
}
.t3{
  font-size: 16px;
  line-height: 1.6;
  padding: 0 50px 0 50px;
}



.logo{
  width:105px;
  height: 70px;
}
.mylogo{
  width:70px;
  height: 70px;
}

.dropmenu{
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}


.login:hover .dropmenu{
  display: flex;
}



/* topedutourist.css */

.container{
  margin-top: 70px ;
justify-content: space-between; 
}
.row{
  display: flex;
}

.col-md-4{
  flex: 33.3px;
  padding: 5px;
  margin: 0 50px 0;
}
.my{
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
.home-page {
  text-align: center;
}

.people-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.mann{
  text-align: center;
}
@media only screen and (max-width:476px) {
 .parent12{
 height:460px;
 }
.transparent-image{
    padding: 0px;


  }
  .parent{
    height: 50vh;
  }
  .m{
 
    padding: 0;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: space-between;
  }
  .cnttt{

  width:100%;
    height:67vh ;
  }
  h1.head_text{
    padding-top: 10%;
    font-size: 16px;
  }
  .transparent-image p{
    padding: 5px 0px 0px 0px;
  }

  .transparent-image button {
    margin:20px 0px 0px 0px;
  } 
  button.btn1{
    margin-bottom:40px;
    margin-left:-15px;
    padding:10px;
    transform: scale(0.7);
    height: 3.125rem;
  }
  .se{
    flex-direction: column;
  }
  img.homeimg{
    width:100%;
   
    transform: scale(2);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
}
  .irc{
    width: 100%;
    margin:0px 0px 10px 10px;
    padding:5px;
    flex-direction: column;
  }
  .visit{
    padding-left:10px;
   
    margin-bottom: 20px;
  }
  .vs2{
    margin-bottom: 0px;
  }
  .travel{
    padding:17px;
    padding-bottom: 0;
    padding-left: 10px;
    
  }
  .mains{
    width:100%;
    margin: 150px 0 0 0;
    display: flex;
    flex-direction: column;
  
  }

  .transparent button.btn2{
    margin:9rem 0rem 0rem 0.5rem;
    height: 40px;
  }
  .left{
    width: 300px;
  }
  .left h1{
    margin: 15px;
    font-size: 24px;
    align-items: center;
    justify-content:space-between;
  }
  .travel_text{
  font-size: 16px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  }
  .container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }
  .left1, .right2 {
    width: 100%;
    
  }
  .r2{
    font-size: 28px;
    margin-left: 35%;
    padding: 0px 0px 0px 20px;
    margin: auto;
  }
  .t2 {
    font-size: 16px;
    line-height: 1.2;
    padding: 20px;
    width:auto ; 
   justify-content: center;

  }
  
  .row{
    flex-direction: column;
  }
  .pp{
    width:93%;
    margin:10px;
    padding:10px;
    gap:10px;
    display: flex;
    flex-direction: column;
  }
 
  .my{
    width:175px;
    height:175px;
    display: block;  
   margin-left: auto;  
   margin-right: auto;  
  padding: 5px; 
  
  }
  .m1{
    /* margin-left: 50px; */
    margin-top: 40px;
    padding:10px;
  }
  .caption{
  float: none;
 text-align:center;
  }
  .pp1{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0px;
    margin:10px;
  }
  .t3{
    font-size: 16px;
    line-height: 1.6;
    align-items: center;
    justify-content: center;
    margin:15px;
    padding:10px;
  }

 .transparent{
  height: 160px;
 }
}

