.sresult{
    padding: 100px 50px 0 50px;
   display: flex;
   
   
   
}
.rresultprop{
 
    display: grid;
    grid-template-columns: auto auto ;
    row-gap: 20px;
   justify-content: space-evenly;
   align-items: center;
    width: 100%;
    flex-direction: row;
}

.ind{
    box-shadow: 2px 2px 7px grey;
    padding: 20px;
  
   

}
.cyt{
    flex-direction: column;
    display: flex;
}
.flexer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width:476px){
   
    .flexer{
        display: flex;
        flex-direction: column;
    }
    .rresultprop{
 
        display: flex;
        grid-template-columns: auto auto ;
        row-gap: 20px;
       justify-content: space-evenly;
       align-items: center;
        width: 900px;
        flex-direction: column;
    }
    .nam{
        margin-right: 0;
    }
    .nam1{
        margin-right: 0;
    }
    .iconss {
        
        margin-top: 15px;
        
    }
    .btn7{
        width: 130px;
        margin: auto;
        margin-top: 10px;
    }
}