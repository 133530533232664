
  .content {
    width: 100%;
    margin-top: 80px;
  }
  #qwery{
    width:350px
  }
  .ct{
    margin-top: 16px;
    background-color: green;
  }
  .img0{
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 0.5;
  }
  
  .parent700{
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .share-option{
   
    height: fit-content;
    margin: auto;
  }
  .cnttt23{
    position: absolute  ;
    display: flex;
    flex-direction: column  ;
    align-items: center;
   
    z-index: 99;
    text-align: center;
   width: 100%;
   height: 100vh;
  }

  @media (max-width: 768px) {
    .share-option {
      padding: 0.5rem;
    }
    /* :where(.css-dev-only-do-not-override-42nv3w).ant-btn-default {
      width: 9.375rem;
    }
    :where(.css-dev-only-do-not-override-42nv3w).ant-btn-primary:disabled, :where(.css-dev-only-do-not-override-42nv3w).ant-btn-primary.ant-btn-disabled {
        width: 9.375rem;  
    } */

    .dev{
      width:9.375rem;
    }
    .content{
        margin-top: 50px;
    }
    #qwery{
        width:9.375rem;
      }
      .ct{
        width:9.375rem
      }
      .back-link{
        display: none;
      }
      .img0{
        height: 46.875rem;
      }
  }