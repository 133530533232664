/* profilecompletion.css */
.ss{
  width: 170px;
  border: 1px solid #4bb36d;
  border-radius: 5px;
height:32px
}


.box{
  box-shadow: 0 0 10px rgba(50, 49, 49, 0.2);
}
.wid{
  
  height:37px;
    padding: 10px;
    border: 1px solid #4bb36d;
    border-radius: 5px;
   width:700px;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    
    border-color: #448f5d;
}
.ant-form-item-required:before {
  color: green !important;
}

.profile-us-container {
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  
  .profile-us-header {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-top: 30px;
  }
  .Touchcolor1{
    color:#326242;
  }
  
  .profile-us-innerheader{
    font-size: medium;
    color:#333;
    text-align: center;
    margin-bottom: 50px;
  }
  .profile-form-container {
    margin: 40px 20px 20px 20px;
    padding :20px;
    box-shadow: 0 0 10px rgba(50, 49, 49, 0.2);
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .profileform-group {
    margin-bottom: 30px;
  
  } 
  
  .profileform-group label {
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
  }
  .inputt3{
    height:15px;
    padding: 10px;
    border: 1px solid #4bb36d;
    border-radius: 5px;
   width:700px;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    
  }
  
.inputt31{
  height:38px;
    padding: 10px;
    border: 1px solid #4bb36d;
    border-radius: 5px;
   width:700px;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    
    border-color: #448f5d;
}
  
.inputt3,
  textarea:focus {
    outline: none;
    border-color: #448f5d;
  }
  
  .submit-btn1 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #2a5438;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width:720px;
  }
  
  .submit-btn1:hover {
    background-color: #297542;
  }
  .str{
    flex-direction: row;
  }
  .back-link {
    display: block;
    margin-top: 20px;
    text-align: center;
    color: #4bb36d;
    text-decoration:none;
    font-size: 1.1rem;
  }
  .nm2{
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
    text-align: left;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  @media (max-width: 476px){
    h2.profile-us-header{
      padding: 10% 0 0 0;
    }
    .profile-us-innerheader{
      margin-bottom: 20px;
    }
    img{
        width:100%;
      }
      input#name1{
        width:90%;
      }
      input#email1{
        width: 90%;
      }
      input#contact_person_designation{
        width: 90%;
      }
      input#mobile1{
       width: 90%;
      }
      input#host_name{
        width: 90%;
       }
       input#pincode{
        width: 150px;
        
       }
       input#pincode{
        width: 150px;
        
       }
      select#dropdown1{
        width: 97%;
      }
      input#year_of_establishment{
        width: 100%;
      }
      
   
      .submit-btn1{
        width:100%;
      }
      .back-link {
        align-content: center;
      }
     .wid{
      width: 260px;
     }
     .uploa{
      width:250px
     }
     .ss{
      width: 250px;
     }
     .str{
      flex-direction: column;
      width:800px
    }
    input#dietary_restrictions{
      width: 90%;
      
     }
     input#nationality{
      width: 90%;
      
     }
     input#e{
      width: 90%;
      
     }
    .pin{
      width: 250px;
    }
}