

  .grid-container{
    margin: 0;
    padding: 0;
    background-color: #1d2634;
    color: #9e9ea4;
    font-family: 'Montserrat', sans-serif;
  }


  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    align-items: flex-start;
  }
  .l1{
    text-align:left;
    
  }
  .l2{
    display: flex;
    flex-direction: row;
   justify-content: space-between;
    width: 100%;
    padding: 5px;
    margin-top: -30px;
  }
  .icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
  }
  .icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
  }
  .icon, .icon_header {
    margin-right: 5px;
  }

 
  .grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }

  /* Header  */
  .header {
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  }

  .menu-icon {
    display: none;
  }
  .logom {
    width: 35px;
    height: 35px;
   margin-top: 20px;
   border-radius: 50%;
  }
  /* End Header  */

  /* Sidebar  */
  #sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #263043;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 210px;
  
  }
 

  .dashboard-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 0px 10px;
    box-shadow: 0 2px 4px #b4753b;
    
  }

  .dashboard-container > span {
    display: none;
  }

  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;


  }

  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }

  .sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
    text-align: justify;
  }

  .sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  .sidebar-list-item > a {
    text-decoration: none;
    color: #9e9ea4;
  }

  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    
    z-index: 12 !important;
  }


  /* End Sidebar  */


  /* Main  */  
  .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }

  .main-title {
    display: flex;
    justify-content: space-between;
  }

  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
   
  }

  .card:first-child {
    background-color: #2962ff;
  }

  .card:nth-child(2) {
    background-color: #ff6d00;
  }

  .card:nth-child(3) {
    background-color: #2e7d32;
  }

  .card:nth-child(4) {
    background-color: #d50000;
  }
  .card:nth-child(5) {
    background-color: #13a862;
  }

  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-inner > .card_icon {
    font-size: 25px;
  }

  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }

  /* End Main  */
  


  /* Medium <= 992px */

  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }

    #sidebar {
      display: none;
      width: 190px;
      
    }
   

    .menu-icon {
      display: inline;
    }

    .sidebar-title > span {
      display: inline;
    }
 
    .close_icon {
      color: #fff;
      margin-left: 30px;
      margin-top: 20px;
      cursor: pointer;
      display: inline;
    }
    .l1 {
     
      
      font-size: 25px;
  }
  .l2{
    gap:12px;
    margin-top: -30px;
  }
  }

  /* Small <= 768px */

  @media screen and (max-width: 768px) {
    .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
      
    }
    
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }

  /* Extra Small <= 576px */

  @media screen and (max-width: 576px) {
    .hedaer-left {
      display: none;
    }
    
  }