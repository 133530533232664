/* login.css */
.login-container {
    max-width: 400px;
    width: 400px;
    margin: 70px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    
  }
  
  
  .tredul-logo{
    width: 30px;
    margin: 0px auto 12px auto;
    padding-bottom: 20px;
    display: table;
   
  }
  .log{
   
    display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 8px;
      margin: auto;
  }
  .head{
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 30px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #16191c;
    padding: -10px;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  
  
  
  input[className="passe"],
.passe,
  input[type="usertype"],
  button {
    margin: 10px 0;
    padding: 10px;
    box-shadow: 2px 2px 5px gray;
    border-radius: 5px;
    width:400px;
    border: none;
  }
  .rr{
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width:400px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    width: 422px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  a {
    color: #4bb36d;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  .usertype{
    margin-left: 10px;
    
  }
  .mr{
    margin-left: 20px;
    
  }
  .mylogo{
    width: 70px;
    height: 70px;
    transform: scale(1.9);
  }

  @media screen and (max-width:476px){
   
     button.btn60{
     width:240px;
     
  }
  .mylogo {
    width: 70px;
    height: 70px;
    transform: scale(1.4);
}
   .login-container{
     padding:7%;
     width:250px;
     align-items: center;
     margin-top:70px;
     height:40%;
     }
   form{
     width:250px;
  }
  input[type="E-mail"], .passe, input[type="usertype"], button {
    margin: 10px 0;
    padding: 10px;
    box-shadow: 2px 2px 5px gray;
    border-radius: 5px;
    width: 220px;
    border: none;
}
 .head{
  margin-top: 5px;
 }
  
  input[type="E-mail"],
  input[type="password"],
  button {
    width:220px;
 
 }
 .rs{
  margin-top: 5px;
  padding-left: 0;
 }
 }