/* AboutUs.css */
.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-us {
    font-size: 2rem;
    color: #333;
   text-align: center;
  }
  
  .about-us-content {
    margin-top: 20px;
  }
  
  .about-us-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    content: justify;
    color: #787777;
  }
  
  .back-link {
    display: inline-block;
    margin-top: 20px;
    color: #4bb36d;
    text-decoration: none;
    align-self: center;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  .r{
    text-align: justify;
  }
  @media screen and (max-width:476px){
    .about-us{
     margin: 20px;
     align-content: center;
     
    }
    .Aback-link {
      align-content: center;
     }
   .r {
      margin-top: 30px;
      padding: 10px;
  }
  }